import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import {
    Management,
    b64Uri,
    getElement,
    getJson,
    loadInputInteger,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class PaymentmethodModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        this.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnAdd = Target.closest(".add");
            const BtnRemove = Target.closest(".remove");

            if (Target.name == "need_bank") {
                if (parseInt(Target.value)) {
                    Form.elements.purchasesaccount_id.removeAttribute(
                        "required"
                    );
                    Form.elements.salesaccount_id.removeAttribute("required");
                } else {
                    Form.elements.purchasesaccount_id.setAttribute(
                        "required",
                        ""
                    );
                    Form.elements.salesaccount_id.setAttribute("required", "");
                }
            }

            if (BtnAdd) {
                const Tr = BtnAdd.closest("tr");
                const Tr2 = Tr.cloneNode(true);
                const P = Tr.parentElement;
                P.appendChild(Tr2);

                loadInputInteger();
            }

            if (BtnRemove) {
                const Tr = BtnRemove.closest("tr");
                const P = Tr.parentElement;

                if (P.children.length > 1) {
                    P.removeChild(Tr);
                }
            }
        });

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data, type) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.name.value = data.name;

        if (data.days.length) {
            data.days.sort((a, b) => Number(a.days) - Number(b.days));

            const D = Form.elements["days[]"];
            const Tr = D.closest("tr");
            const P = Tr.parentElement;

            data.days.forEach((item) => {
                const Tr2 = Tr.cloneNode(true);
                console.log(item);
                Tr2.querySelector("input").value = item.days;
                console.log(Tr2);
                console.log(Tr2.querySelector("input"));
                P.appendChild(Tr2);
            });
            Tr.remove();

            loadInputInteger();
        }

        Form.elements.description.value = data.description;
        Array.from(Form.elements.need_bank).find(
            (item) => item.value == data.need_bank
        ).checked = true;

        if (!data.need_bank) {
            console.log(Form.elements);
        }

        setSelect2Ajax(Form.elements.type, data.type, type);

        let managament = data.managements.find(
            (item) => item.company_id == Management.company_id
        );

        if (managament) {
            // dataAccounting.concat = [];

            if (managament.pivot.salesaccount) {
                setSelect2Ajax(
                    Form.elements.salesaccount_id,
                    managament.pivot.salesaccount.id,
                    `${managament.pivot.salesaccount.number} - ${managament.pivot.salesaccount.name}`
                );

                // dataAccounting.concat["salesaccount_id"] =
                //     managament.pivot.salesaccount.id;
            }

            if (managament.pivot.purchasesaccount) {
                setSelect2Ajax(
                    Form.elements.purchasesaccount_id,
                    managament.pivot.purchasesaccount.id,
                    `${managament.pivot.purchasesaccount.number} - ${managament.pivot.purchasesaccount.name}`
                );

                // dataAccounting.concat["purchasesaccount_id"] =
                //     managament.pivot.purchasesaccount.id;
            }

            // dataAccounting.accountingplans = Object.values(
            //     dataAccounting.concat
            // );
        }
    }
}

window.customElements.define("paymentmethod-modal", PaymentmethodModal, {
    extends: "div",
});
